import React, { useEffect } from 'react'
import styles from './router.module.scss'
import Footer from "../footer/footer"
import Header from "../header/header"
import Button from '../buttons/button'
import { Link } from 'gatsby'

export default function Router() {
  useEffect(() => {
    window.sessionStorage.clear()
  }, [])

  return (
    <section className={styles.section}>
      <Header />
      <div className={styles.container}>
        <img className={styles.plate} src="/plate.png" alt="" />
        <h1>Muscogee (Creek) Nation Tag Services</h1>
        <div className={styles.topButtons}>
          <Link to="/renew-tag" className={styles.renewTagLink}>
            <Button {...{
              label: "Renew Tag",
              direction: 'right'
            }} />
          </Link>
          <Link to="/renew-boat" className={styles.renewTagLink}>
            <Button {...{
              label: "Renew Boat",
              direction: 'right'
            }} />
          </Link>
        </div>
        
        <div className={styles.buttons}>
          <Link to="/lien-title-request">
            <Button {...{
              label: 'Lien Holder Title',
              direction: 'right'
            }} />
          </Link>
          <Link to="/duplicate-title-request">
            <Button {...{
              label: 'Duplicate Title',
              direction: 'right'
            }} />
          </Link>
          <Link to="/repo-instructions">
            <Button {...{
              label: 'Repo Instructions',
              direction: 'right'
            }} />
          </Link>
          <Link to="/registration-request">
            <Button {...{
              label: 'Registration Request',
              direction: 'right'
            }} />
          </Link>
          <Link to="/change-address">
            <Button {...{
              label: 'Change of Address',
              direction: 'right'
            }} />
          </Link>
          <Link to="/decal-replacement">
            <Button {...{
              label: 'Decal Replacement',
              direction: 'right'
            }} />
          </Link>
          <Link to="/disability-placard">
            <Button {...{
              label: 'Handicap Placard',
              direction: 'right'
            }} />
          </Link>
          <Link to="/personalized-tag">
            <Button {...{
              label: 'Personalized Tag',
              direction: 'right'
            }} />
          </Link>
          <Link to="/vehicle-info">
            <Button {...{
              label: 'Vehicle Information',
              direction: 'right'
            }} />
          </Link>
        </div>
       
      </div>
      <Footer />
    </section>
  )
}