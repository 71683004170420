import React from 'react'
import Layout from '../components/layout/layout'
import Development from '../components/development/development'
import Router from '../components/router/router'

export default function Home() {
  return (
    // <Development/>
    <Layout>
      <Router />
    </Layout>
  );
}
